const user = {
  name: "Xylon Funes",
  personalDescription:" With a strong background in Information Science, I am proficient in JavaScript, Java, and Python. I am experienced in working both individually and as a team leader. In my spare time, I have also undertaken personal projects, such as building a new platform to help people learn to code in an innovative way.",
  projectDetail: " Developing personal projects is a valuable way to demonstrate technical skills and pursue personal interests. Below, I have highlighted several notable projects that I have completed. These projects encompass a diverse range of technologies and frameworks, and include the development of web applications and exploration of new technologies.",

  education: [
    {
      id: 1,
      degree: "Master's Degree",
      university: "University of Maryland",
      specialization: " Information Systems",
      graduationDate: "December 2024",
    },

    {
      id: 2,
      degree: "Bachelor's Degree",
      university: "University of Maryland",
      specialization: " Information Science",
      graduationDate: "December 2023",
    },
    {
      id: 3,
      degree: "Associate's Degree",
      university: "Montgomery College",
      specialization: " Computer Science",
      graduationDate: "December 2020",
    },
  ],
  workExperience: [
    {
      id: 1,
      work: "IT Support Desktop Technician",
      where: "Grady Management",
      dates: "Jan 2022 - Dec 2022",
      responsabilities: [
        "Ensured safe and timely delivery of goods to various destinations while maintaining excellent customer service",
        "Conducted pre- and post-trip inspections to ensure vehicles were in good condition",
        "Maintained accurate records of deliveries and mileage for reporting and accounting purposes",
        "Communicate effectively with passengers and dispatchers, using two-way radios and other communication devices.",
      ],
    },

    {
      id: 2,
      work: "Supervisor",
      where: "High Sierra Pools",
      dates: "Jan 2021 - Sep 2022",
      responsabilities: [
        "Ensured that lifeguards perform in accordance with established safety regulations and policies",
        "Ensured that lifeguards are fully competent in first aid, CPR, and all water rescue techniques",
        "Interfaced with patrons regarding concerns, problems, or any other significant issues.",
        "Developing and implementing safety policies and procedures to reduce the risk of accidents and injuries.",
      ],
    },
    {
      id: 3,
      work: "Continental Pools",
      where: "Lifeguard",
      dates: "Jan 2022 - Dec 2022",
      responsabilities: [
        "Ensured safe and timely delivery of goods to various destinations while maintaining excellent customer service",
        "Conducted pre- and post-trip inspections to ensure vehicles were in good condition",
        "Maintained accurate records of deliveries and mileage for reporting and accounting purposes",
        "Communicate effectively with passengers and dispatchers, using two-way radios and other communication devices.",
      ],
    },
  ],

  personalProjects: [
    {
      id: 1,
      nameOfProject: "Music Chart Visualizer",
      github:"https://github.com/Mokleins/final/tree/master",
      prototype:"https://mokleins.github.io/final/",
      description: "This web application visualizes Spotify's most popular tracks of different genres. Features include sorting songs by popularity and in alphabetical order, and displaying real-time charts and popularity ratings using the Spotify API.",
    },
    {
      id: 2,
      nameOfProject: "Random Verses",
      github:"https://github.com/Mokleins/Project--1",
      prototype:"https://mokleins.github.io/project-1/",
      description: "This project utilizes a public API from the Bible, allowing users to retrieve random verses from the text. Upon each user click of the random verse feature, the application dynamically generates a unique set of five Bible verses for the user to explore.",
    },
    {
      id: 3,
      nameOfProject:"Project 3",
      github:"https://github.com/Mokleins",
      prototype:"https://github.com/Mokleins",
      description:" "
    }
  ]
};

export default user;
