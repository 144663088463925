import React from "react";
import "../styles/AboutMe.css";

function AboutMe({personalDescription}) {
  return (
    <section className="about-me">
      <div className="about-me__container">
        <h2 className="about-me__heading">Software Developer</h2>
        <p className="about-me__text">
          {personalDescription}
        </p>
      </div>
    </section>
  );
}

export default AboutMe;
