import React from "react";
import "../styles/Project.css";

function Project({ projects, projectsDetails}) {
  console.log(projects);
  const renderRespon = projects.map((project) => {
    return (
      <ul key={project.id} className="personal-projects__item">
        <h3>{project.nameOfProject}</h3>
        <p>{project.description}</p>
        <a
        href={project.github}>
          <button className="button" aria-label="View on Github">
            Github
          </button>
        </a>
        <a
        href={project.prototype}>
          <button className="button" aria-label="View Prototype">
            Prototype
          </button>
        </a>
      </ul>
    );
  });

  return (
    <article className="personal-projects">
      <div className="personal-projects__container">
        <h2 className="personal-projects__heading">Technical Projects</h2>
        <p className="personal-projects__text">
        {projectsDetails}
        </p>
        <ul className="personal-projects__list">{renderRespon}</ul>
      </div>
    </article>
  );
}

export default Project;
