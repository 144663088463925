function Work({ work }) {
  const renderRespon = work.responsabilities.map((responsa) => {
    return <li key={work.responsabilities.indexOf(responsa)}>{responsa}</li>;
  });
  return (
    <>
      <div className="work-experience__job">
        <h3>{work.work}</h3>
        <h4>{work.where}</h4>
        <p>
          <span>Duration: </span>
          {work.dates}
        </p>
        <p>
          <span>Responsibilities:</span>
          {renderRespon}
        </p>
      </div>
    </>
  );
}

export default Work;
