import React from "react";
import "../styles/Education.css";

function Education({education}) {
  const renderRespon = education.map((edu) => {
    return <div key={edu.id}>
      <h3>{edu.degree}</h3>
      <p>{`${edu.university}, ${edu.specialization}, ${edu.graduationDate}`}</p>
    </div>
  });

  return (
    <section className="education">
      <div className="education__container">
        <h2 className="education__heading">Education</h2>
        <ul className="education__list">
          {renderRespon}
        </ul>
      </div>
    </section>
  );
}
export default Education;
