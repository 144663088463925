import React from "react";
import "../styles/Certifications.css";

function Certifications() {
  return (
    <section className="certifications">
      <div className="certifications__container">
        <h2 className="certifications__heading">Certifications</h2>
        <ul className="certifications__list">
          <li className="certifications__item">
            Certified Python Programmer - PCEP Montgomery College
          </li>
          <li className="certifications__item">
            CRLA's International Mentor Training Program Certification 
          </li>
          <li className="certifications__item">
          Certified Pool Operator - High Sierra Pools
          </li>
          <li className="certifications__item">
          Computer Programming - Montgomery College
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Certifications;
