import React from "react";
import "../styles/Languages.css";

function Languages() {
  return (
    <section className="languages">
      <div className="languages__container">
        <h2 className="languages__heading">Languages</h2>
        <ul className="languages__list">
          <li className="languages__item">
            <span>English:</span> Fluent
          </li>
          <li className="languages__item">
            <span>Spanish:</span> Fluent
          </li>
        </ul>
      </div>
      <div className="button-wrapper">
        <a href="mailto:communications@xylonf.com">
          <button className="button-2">Contact Me</button>
        </a>
      </div>
    </section>
  );
}

export default Languages;
