import React from "react";
import "../styles/WorkExperience.css";
import Work from "./Work";

function Workexperience({ workExperience }) {
  const renderWork = workExperience.map((work) => {
    return <Work key={work.id} work={work} />;

  });
  return (
    <section className="work-experience">
      <div className="work-experience__container">
        <h2 className="work-experience__heading">Work Experience</h2>
        {renderWork}
      </div>
    </section>
  );
}

export default Workexperience;
