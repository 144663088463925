import React from "react";
import AboutMe from "./AboutMe";
import Workexperience from "./Workexperience";
import Project from "./Project";
import Education from "./Education";
import Certifications from "./Certifications";
import Languages from "./Languages";
import user from "../data/resume";

function App() {
  return (
    <>
      <AboutMe personalDescription={user.personalDescription}/>
      <Project projects={user.personalProjects} projectsDetails={user.projectDetail}/>
      <Education education={user.education} />
      <Workexperience workExperience={user.workExperience} />
      <Certifications />
      <Languages />
    </>
  );
}

export default App;
